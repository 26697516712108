<script setup lang="ts">
import type { FavoriteItem } from "~/types";
import { FavoriteLabelEdit } from "#components";
import { useModal } from "vue-final-modal";
import {
  getSekouDetailPath,
  getSekouImagePath,
  getPlanImagePath,
} from "~/utils";
interface Props {
  title: string;
  list: FavoriteItem[];
  type: "plan" | "sekou";
}

const props = defineProps<Props>();
const emits = defineEmits<{
  (e: "close"): void;
  (e: "delete", value: { cd: string; num?: number }): void;
  (e: "edit", value: { label: string[]; cd: string; num?: number }): void;
}>();

//  ラベル編集
const { open, close, patchOptions } = useModal({
  component: FavoriteLabelEdit,
  attrs: {
    onSubmit(item: { label: string[]; cd: string; num?: number }) {
      emits("edit", { label: item.label ?? [], cd: item.cd, num: item.num });
      close();
    },
    onClose() {
      close();
    },
  },
});

// ラベル編集を開く
function openModal(cd: string, num?: number) {
  const item = props.list.find((e) => e.cd == cd && e.num == num);
  if (item) {
    patchOptions({
      attrs: {
        ...item,
      },
    });

    open();
  }
}
</script>

<template>
  <Transition name="fade">
    <div>
      <p class="border-b mb-4">{{ title }}</p>
      <ListResponsiveWrapper :sp="2" :pc="4" class="relative mb-8">
        <ListResponsiveItem
          class="flex relative"
          v-for="(item, idx) in list"
          :key="`${item.cd}_${item.num ?? 1}`"
          :rounded="true"
        >
          <NuxtLink
            :to="
              type == 'sekou'
                ? getSekouDetailPath(parseInt(item.cd), item.num)
                : `/plan/detail/${item.cd}`
            "
            @click="emits('close')"
          >
            <ImageEl
              provider="customimgproxy"
              class="w-full rounded-t-lg mb-2"
              :src="
                type == 'sekou'
                  ? getSekouImagePath(parseInt(item.cd), item.num)
                  : getPlanImagePath(parseInt(item.cd))
              "
              :alt="item.title_1"
              width="500"
              height="375"
              sizes="sm:100% md:500px"
              :loading="idx > 4 ? 'lazy' : 'eager'"
              format="webp"
            />

            <div class="p-2">
              <p v-if="item.comment" class="text-sm line-clamp-2">
                {{ item.comment }}
              </p>
              <p v-else-if="item.title_1" class="text-sm line-clamp-2">
                {{ item.title_1 }}
              </p>
              <p v-else class="text-sm">
                <span v-if="item.area_1 && item.area_2">{{
                  item.area_1 + item.area_2
                }}</span>
                <span class="line-clamp-1">{{ item.title_2 }}</span>
              </p>
            </div>
            <ul
              v-if="item.label && item.label.length"
              class="flex flex-wrap items-center gap-1 p-2"
            >
              <li
                v-for="label in item.label"
                class="bg-white border rounded-lg px-1 py-0.5 text-xs"
              >
                <span class="mr-1">{{ label }}</span>
              </li>
            </ul>
          </NuxtLink>

          <button
            @click="emits('delete', { cd: item.cd, num: item.num })"
            class="flex absolute -top-4 -right-2 z-[2] p-2 bg-white rounded-full text-red-600 drop-shadow hover:bg-red-600 hover:text-white"
          >
            <Icon size="24" name="mdi:delete" />
          </button>
          <button
            @click="openModal(item.cd, item.num)"
            class="flex absolute top-8 -right-2 z-[2] p-2 bg-white rounded-full text-gray-600 drop-shadow hover:bg-gray-600 hover:text-white"
          >
            <Icon size="24" name="mdi:pencil" />
          </button>
        </ListResponsiveItem>
      </ListResponsiveWrapper>
    </div>
  </Transition>
</template>
