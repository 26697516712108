<script setup lang="ts">
// $routeの型が解決できないみたい
const route = useRoute();
</script>

<template>
  <component
    class="flex items-center gap-2 px-1 w-2/3 max-w-[180px]"
    :is="route.path == '/' ? 'h1' : 'div'"
  >
    <NuxtLink to="/">
      <ImageEl
        src="/images/logo_1.png"
        alt="外構・庭園・エクステリア・水まわり工事のファミリー庭園"
        width="766"
        height="195"
        sizes="160px"
        format="webp"
      />
    </NuxtLink>
  </component>
</template>
